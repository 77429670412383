import React from 'react'
import Layout from '../components/layout'
import {graphql} from 'gatsby'
import PopularPosts from '../components/popularPosts';
import {AboutUs} from '@cg-squad/ui-components';
import {Basic} from '../components/SEO';
import {metadata} from '../../config';
import {Helmet} from 'react-helmet'

function AboutUsPage(props) {
  const seo = {
    title: `About Us | ${metadata.defaultTitle}`,
    description: metadata.description
  }

    return (
        <Layout relativePath="/">
          <Basic seo={seo}/>
          <Helmet>
            <link rel="canonical" href={`${metadata.url}/about-us`}/>
          </Helmet>
            <main className="wrapper" role="main" data-datocms-noindex>
                <div className={"w-full flex"}>
                    <AboutUs page={props.data.page} title={"About Health Times"} allAuthors={props.data.allAuthors}/>
                    <PopularPosts className="hidden lg:block"/>
                </div>
            </main>
        </Layout>
    );
}

export const query = graphql`
    query Authors {
        page: datoCmsPage(slug: {eq: "about-us"}, website: {elemMatch: {name: {eq: "ht"}}}) {
            content
            slug
        }
        allAuthors: allDatoCmsAuthor(filter: {website: {elemMatch: {name: {eq: "ht"}}}}) {
            nodes {
                name
                slug
                id: originalId
                profilePhoto {
                    url
                }
                bio
                linkedin
                email
                twitter
            }
        }
    }
`

export default AboutUsPage;
